export const SET_ADMIN = "SET_ADMIN";
export const UNSET_ADMIN = "UNSET_ADMIN";

export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

export  const SIGNUP_ADMIN ="SIGNUP_ADMIN"; 


export  const SET_REGISTRATION_ERROR ="SET_REGISTRATION_ERROR"; 
export  const CLEAR_REGISTRATION_ERROR ="CLEAR_REGISTRATION_ERROR"; 

export  const SET_UPDATECODE_ERROR ="SET_UPDATECODE_ERROR"; 
export  const CLEAR_UPDATECODE_ERROR ="CLEAR_UPDATECODE_ERROR"; 

