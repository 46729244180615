import { GET_HOST, ENABLE_DISABLE_HOST, SET_ENABLE_HOST, UNSET_ENABLE_HOST } from "./types";

const initialState = {
  host: [],
  enableDone:false,
  message:null
  
};

const hostReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST:
      return {
        ...state,
        host: action.payload,
      };
    case ENABLE_DISABLE_HOST:
      return {
        ...state,
        host: state.host.map((host) => {
          if (host._id === action.payload._id)
            return {
              ...host,
              isAccepted: action.payload.isAccepted,
            };
          else return host;
        }),
      };

      case SET_ENABLE_HOST :
        return {
          ...state ,
          enableDone:true,
          message: action.payload || null

        }
        case UNSET_ENABLE_HOST:
          return {
            ...state ,
            enableDone:false,
            message: null
          }
    default:
      return state;
  }
};

export default hostReducer;
