import React, { Fragment, useEffect, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//bounce loader
import { BounceLoader } from "react-spinners";

import { Switch, BrowserRouter, Route } from "react-router-dom";

import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import AuthRoute from "./util/AuthRouter";
import PrivateRoute from "./util/PrivateRouter";
import Admin from "./pages/Admin";
import { IdleTimeoutManager } from "idle-timer-manager";

import { SET_ADMIN, UNSET_ADMIN } from "./store/admin/types";
import Registration from "./pages/Registration"
import UpdateCode from "./pages/UpdateCode"
import axios from "axios";
import Spinner from "./Components/Spinner";

// import UserTable from "./Components/Table/UserTable";

const App = () => {
  const isAuth = useSelector((state) => state.admin.isAuth);
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);
  const token = localStorage.getItem("token");
  const key = localStorage.getItem("key");

  // useEffect(() => {

  //   axios
  //     .get("/login")
  //     .then((res) => {
  //       console.log('res.data', res.data)
  //       setLogin(res.data.login);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: SET_ADMIN, payload: token });
  }, [token, key, dispatch]);

  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout: 1800, //30 min (in sec)
      onExpired: (time) => {
        dispatch({ type: UNSET_ADMIN });
        return (window.location.href = "/");
      },
    });

    return () => {
      manager.clear();
    }; //eslint-disable-next-line
  }, []);

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <BounceLoader
            css={`
              margin: auto;
            `}
            size={60}
            color="#3d4977"
          />
        </div>
      }
    >
      <BrowserRouter>
        <Switch>
          {/* <AuthRoute exact path="/" component={login ? Login : Registration} />
          {login && <AuthRoute exact path="/" component={Login} />}
          <AuthRoute exact path="/code" component={UpdateCode} />
          <AuthRoute path="/Registration" component={Registration} /> */}
          {/* <PrivateRoute path="/admin" component={Admin} /> */}
          <AuthRoute exact path="/" component={Login} />
          <AuthRoute exact path="/login" component={Login} />
          <Route exact path="/forgot" component={ForgotPassword} />
          <Route exact path="/change/:id" component={ChangePassword} />
          {isAuth && <Route path="/admin" component={Admin} />}
        </Switch>
        <Spinner />
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
