import axios from "axios";

import {
  GET_COMPLAIN,
  SOLVED_COMPLAIN,
} from "./types";
import { apiInstanceFetch } from "../../util/api";


export const getComplain = (type) => (dispatch) => {
  apiInstanceFetch
    .get(`/complain?type=${type}`)
    .then((res) => {
      dispatch({ type: GET_COMPLAIN, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const solvedComplain = (id) => (dispatch) => {
  axios
    .patch(`/complain/${id}`)
    .then((res) => {
      ;
      dispatch({ type: SOLVED_COMPLAIN, payload: res.data.data });
    })
    .catch((error) => console.log(error));
};

