import axios from "axios";

import { GET_HOST, ENABLE_DISABLE_HOST, SET_ENABLE_HOST } from "./types";
import { apiInstanceFetch } from "../../util/api";

export const getHost = () => (dispatch) => {
  apiInstanceFetch
    .get("/host")
    .then((res) => {
      dispatch({ type: GET_HOST, payload: res.data });
    })
    .catch((error) => console.log(error));
};

export const enableDisableHost = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`/host/enableDisable/${id}`)
    .then((res) => {
      dispatch({ type: ENABLE_DISABLE_HOST, payload: res.data });
      dispatch({
        type: SET_ENABLE_HOST,
        payload: res.data.isAisAccepted,
      });
      
    })
    .catch((error) => console.log(error));
};
