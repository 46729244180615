import { GET_USER, BLOCK_UNBLOCK_USER, SET_ENABLE_HOST, UNSET_ENABLE_HOST } from "./types";

const initialState = {
  user: [],
  enableDone:false,
  message:null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case BLOCK_UNBLOCK_USER:
      return {
        ...state,
        user: state.user.map((user) => {
          if (user._id === action.payload._id)
            return {
              ...user,
              block: action.payload.block,
            };
          else return user;
        }),
      };

      case SET_ENABLE_HOST :
        return {
          ...state ,
          enableDone:true,
          message: action.payload || null

        }
        case UNSET_ENABLE_HOST:
          return {
            ...state ,
            enableDone:false,
            message: null
          }
    default:
      return state;
  }
};

export default userReducer;
