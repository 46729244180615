import React, { useEffect, useState } from "react";
import { Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { connect, useSelector } from "react-redux";
import { signupAdmin } from "../store/admin/action";
import Wrapkit from "../assets/images/big/icon.png";

const Registration = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [purchaseCodeError, setPurchaseCodeError] = useState(null);

  const [openEmailError, setOpenEmailError] = useState(false);
  const [openPasswordError, setOpenPasswordError] = useState(false);
  const [openNewPasswordError, setOpenNewPasswordError] = useState(false);
  const [openPurchaseCodeError, setOpenPurchaseCodeError] = useState(false);

  const [error, setError] = useState({
    email: "",
    password: "",
    newPassword: "",
    code: "",
  });

  const errors = useSelector((state) => state.admin.registrationError);

  useEffect(() => {
    if (typeof errors === "string") {
      setError(errors);
      setOpenEmailError(true);
      setEmailError(errors);
      setOpenPasswordError(true);
      setPasswordError(errors);
      setOpenNewPasswordError(true);
      setNewPasswordError(true);
      setOpenPurchaseCodeError(true);
      setPurchaseCodeError(errors);
    }
    if (typeof errors === "object") {
      errors.map((error) => {
        if (error?.email) {
          setEmail("");
          setOpenEmailError(true);
          setEmailError(error?.email);
        }

        if (error?.password) {
          setPassword("");
          setOpenPasswordError(true);
          setPasswordError(error?.password);
        }
        if (error?.newPassword) {
          setNewPassword("");
          setOpenNewPasswordError(true);
          setNewPasswordError(error?.password);
        }
        if (error?.code) {
          setCode("");
          setOpenPurchaseCodeError(true);
          setPurchaseCodeError(error?.code);
        }
        return true;
      });
    }
  }, [errors]);

  useEffect(() => {
    console.log("openEmailError", openEmailError);
  }, [openEmailError]);

  const isEmail = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val);
    return validNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password || !code || !newPassword || !isEmail(email)) {
      let error = {};
      if (!email) error.email = "Email Is Required !";
      if (!password) error.password = "password is required !";
      if (!newPassword) error.newPassword = "new password is required !";
      if (newPassword !== password)
        error.newPassword = "New Password and Confirm Password doesn't match !";
      if (!code) error.code = "purchase code is required !";

      return setError({ ...error });
    } else {
      let login = {
        email,
        password,
        newPassword,
        code,
      };

      props.signupAdmin(login);
    }
  };

  const handleCloseError = () => {
    setOpenEmailError(false);
    setOpenPasswordError(false);
    setOpenNewPasswordError(false);
    setOpenPurchaseCodeError(false);
  };

  return (
    <div class="main-wrapper">
      <Snackbar
        open={openEmailError}
        autoHideDuration={2000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseError} severity="error">
          {emailError}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openPasswordError}
        autoHideDuration={2000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseError} severity="error">
          {passwordError}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openNewPasswordError}
        autoHideDuration={2000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseError} severity="error">
          {newPasswordError}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPurchaseCodeError}
        autoHideDuration={2000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseError} severity="error">
          {purchaseCodeError}
        </Alert>
      </Snackbar>

      <div
        class="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative"
        id="auth"
      >
        <div class="auth-box row">
          <div class="col-md-2"></div>
          <div class="col-lg-8 col-md-7 bg-white">
            <div class="p-3">
              <div class="text-center">
                <img src={Wrapkit} alt="wrapkit" />
              </div>
              <h2 class="mt-3 text-center">Sign Up</h2>
              <p class="text-center">
                Enter your email address and password to access admin panel.
              </p>
              <form class="mt-4">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="text-dark" for="uname">
                        Email
                      </label>
                      <input
                        class="form-control"
                        placeholder="Email"
                        type="email"
                        required
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...error,
                              email: "Email is Required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              email: "",
                            });
                          }
                        }}
                      />
                      {error.email && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {error.email}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="text-dark" for="pwd">
                        Password
                      </label>
                      <input
                        class="form-control"
                        placeholder="Password"
                        type="password"
                        required
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              password: "Password is Required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              password: "",
                            });
                          }
                        }}
                      />
                      {error.password && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {error.password}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="text-dark" for="pwd">
                        Confirm Password
                      </label>
                      <input
                        class="form-control"
                        placeholder="Password"
                        type="password"
                        required
                        value={newPassword}
                        onChange={(e) => {
                          setNewPassword(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              newPassword: "Confirm Password is Required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              newPassword: "",
                            });
                          }
                        }}
                      />
                      {error.newPassword && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {error.newPassword}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="text-dark" for="pwd">
                        Purchase Code
                      </label>
                      <input
                        class="form-control"
                        placeholder="Purchase Code"
                        type="text"
                        required
                        value={code}
                        onChange={(e) => {
                          setCode(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...error,
                              code: "Purchase Code is Required!",
                            });
                          } else {
                            return setError({
                              ...error,
                              code: "",
                            });
                          }
                        }}
                      />
                      {error.code && (
                        <div class="pl-1 text-left">
                          <Typography variant="caption" color="error">
                            {error.code}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>

                  <div class="col-lg-12 text-center">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      class="btn btn-block btn-dark rounded"
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { signupAdmin })(Registration);
