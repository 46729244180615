import axios from "axios";

import {
  CLEAR_LOGIN_ERROR,
  CLEAR_REGISTRATION_ERROR,
  CLEAR_UPDATECODE_ERROR,
  SET_ADMIN,
  SET_LOGIN_ERROR,
  SET_REGISTRATION_ERROR,
  SET_UPDATECODE_ERROR,
  SIGNUP_ADMIN,
  UPDATE_PROFILE,
} from "./types";
import { apiInstanceFetch } from "../../util/api";

export const signupAdmin = (signup) => (dispatch) => {
  dispatch({ type: CLEAR_REGISTRATION_ERROR });

  console.log("/admin/signup", signup);
  axios
    .post("/admin/signup", signup)
    .then((res) => {
      if (res.data.status) {
        debugger;
        dispatch({ type: SIGNUP_ADMIN });

        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      } else {
        debugger
        dispatch({
          type: SET_REGISTRATION_ERROR,
          payload: res.data.message,
        });
      }
    })
    .catch((response) => {
      if (response?.data.message) {
        dispatch({
          type: SET_REGISTRATION_ERROR,
          payload: response.data.message,
        });
      }
    });
};

export const updateCode = (signup) => (dispatch) => {
  dispatch({ type: CLEAR_UPDATECODE_ERROR });

  axios
    .patch("/admin/updateCode", signup)
    .then((res) => {
      if (res.data.status) {
        console.log("res.data", res.data);

        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      } else {
        dispatch({ type: SET_UPDATECODE_ERROR, payload: res.data.error });
      }
    })
    .catch((response) => {
      if (response?.data.error) {
        dispatch({ type: SET_UPDATECODE_ERROR, payload: response.data.error });
      }
    });
};

export const login = (email, password, key, package_) => (dispatch) => {
  dispatch({ type: CLEAR_LOGIN_ERROR });
  axios
    .post("/admin", { email, password, key, package_ })
    .then((res) => {
      if(res.data.status){
        dispatch({ type: SET_ADMIN, payload: res.data.token });
      }else{
        dispatch({ type: SET_LOGIN_ERROR, payload: res.data.message });
      }
    })
    .catch(({ response }) => {
      if (response?.data.error) {
        dispatch({ type: SET_LOGIN_ERROR, payload: response.data.error });
      }
    });
};

export const updateProfile = (profileData) => (dispatch) => {
  axios
    .patch("/admin", profileData)
    .then((res) => {
      dispatch({ type: UPDATE_PROFILE, payload: res.data.data });
    })
    .catch(({ response }) => {
      console.log(response?.data);
    });
};

export const getProfile = () => (dispatch) => {
  apiInstanceFetch
    .get("/admin")
    .then((res) => {
      dispatch({ type: UPDATE_PROFILE, payload: res.data });
    })
    .catch((error) => console.log(error));
};
